<div class="flex-col gap-4 w-100">
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon" aria-hidden="true" svgIcon="user"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100 align-start">
        <div class="flex-col flex-grow">
          <div class="flex-row space-between">
            <label for="standardModeToggle" class="font-weight-bold">Standard Mode</label>
            <ng-container [ngTemplateOutlet]="licenseRequired"></ng-container>
          </div>
          <span class="u_block text-muted info-text">Allow one person to share by entering the Pairing Code.</span>
        </div>
        @if (!isLicenseRequired) {
          <mat-slide-toggle
            [(ngModel)]="model.screenSharingStandard"
            (change)="screenShareModeChange('standard', $event)"
            name="screenSharingStandard"
            id="standardModeToggle"></mat-slide-toggle>
        }
      </div>
      @if (!isLicenseRequired) {
        <div class="flex-row space-between w-100" *ngIf="false">
          <div class="row-entry space-between align-start">
            <label
              class="font-weight-normal"
              for="requirePinConfirmationToggle">
              Require Pin Confirmation
            </label>
            <mat-slide-toggle
              [(ngModel)]="model.settings.screenSharingPinRequired"
              [disabled]="!model.screenSharingStandard"
              name="requirePinConfirmationToggle"
              #screenSharingEnabled="ngModel"
              ngDefaultControl
              id="requirePinConfirmationToggle"></mat-slide-toggle>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon" aria-hidden="true" svgIcon="users"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100 align-start">
        <div class="flex-col flex-grow">
          <div class="flex-row space-between">
            <label
              for="moderatorModeToggle"
              class="font-weight-bold">
              Moderator Mode
            </label>
            <ng-container [ngTemplateOutlet]="licenseRequired"></ng-container>
          </div>
          <span class="u_block text-muted info-text">Allow multiple participants to join a session by entering the pairing code. <span hidden>They will not be able to join using Airplay.</span></span>
        </div>
        @if(!isLicenseRequired) {
          <mat-slide-toggle
            (change)="screenShareModeChange('moderated', $event)"
            [(ngModel)]="model.screenSharingModerated"
            name="moderatorModeToggle"
            id="moderatorModeToggle"></mat-slide-toggle>
        }
      </div>
    </div>
  </div>
</div>
